document.addEventListener("DOMContentLoaded", function () {
  function startCounter(element) {
    const countTo = parseInt(element.getAttribute("data-count-to"));
    if (!isNaN(countTo)) {
      let currentCount = 0;
      const duration = 2000; // Adjust the duration (in milliseconds) as needed.
      const step = countTo / (duration / 1000); // Calculate the increment per second.
      let lastTimestamp;

      function updateCounter(timestamp) {
        if (!lastTimestamp) lastTimestamp = timestamp;
        const deltaTime = timestamp - lastTimestamp;
        lastTimestamp = timestamp;

        currentCount += step * (deltaTime / 1000); // Increment based on time passed.

        let elementSuffix = element.getAttribute("data-counter-suffix");

        if (currentCount <= countTo) {
          element.textContent = Math.round(currentCount);
          if (elementSuffix !== null) element.append(elementSuffix);
          requestAnimationFrame(updateCounter);
        } else {
          element.textContent = countTo; // Ensure it ends at the exact count.
          if (elementSuffix !== null) element.append(elementSuffix);
        }
      }

      requestAnimationFrame(updateCounter);
    }
  }

  function observeCounters() {
    const elements = document.querySelectorAll("[data-count-to]");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startCounter(entry.target);
          observer.unobserve(entry.target);
        }
      });
    });

    elements.forEach((element) => {
      observer.observe(element);
    });
  }

  // Example usage:
  observeCounters();
});
